//prefixes for iana names..
module.exports = [
  'africa',
  'america',
  'asia',
  'atlantic',
  'australia',
  'brazil',
  'canada',
  'chile',
  'europe',
  'indian',
  'mexico',
  'pacific',
  'antarctica',
  'etc'
]
